import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState } from '../../config/Store';
import { useEffect, useRef, useState } from 'react';

const VerticalMenu = () => {
    const [pathname, setPathname] = useState<string>('')
    const currentSelectedModule = useRef<string>('');
    const userInfoData: any = useSelector<RootState, any>((state: any) => state.userInfoData);

    useEffect(() => {
        console.log('window.location.pathname', window.location.pathname);

        setPathname(window.location.pathname);
    }, [window.location.pathname])

    return (
        <>
            <div id="vertical_menu" className="verticle-menu" style={{minWidth: "12%"}}>
                <div className="menu-list">
                    <Link id="t-1" to={'/dashboard'} className={(pathname == "/" || pathname == "/dashboard") ? "menu-item active" : "menu-item"} onClick={() => setPathname('/dashboard')}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.25 2.5H3.75C3.05964 2.5 2.5 3.05964 2.5 3.75V16.25C2.5 16.9404 3.05964 17.5 3.75 17.5H16.25C16.9404 17.5 17.5 16.9404 17.5 16.25V3.75C17.5 3.05964 16.9404 2.5 16.25 2.5Z" stroke="white" stroke-linejoin="round" />
                        <path d="M2.5 9.16602H17.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.0833 9.16667V2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.8333 2.5H13.3333" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.5 7.91602V10.416" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17.5 7.91602V10.416" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                        <span className='nav-text'>Dashbaord</span>
                    </Link>
                    <Link id="t-1" to={'/charging'} className={pathname == "/charging" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/charging')}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83325 4.16732L5.83325 17.5007C5.83325 17.9609 6.20635 18.334 6.66659 18.334H13.3333C13.7935 18.334 14.1666 17.9609 14.1666 17.5007V4.16732C14.1666 3.70708 13.7935 3.33398 13.3333 3.33398H6.66659C6.20635 3.33398 5.83325 3.70708 5.83325 4.16732Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.33325 2.50065V1.66732C8.33325 1.20708 8.70634 0.833984 9.16659 0.833984H10.8333C11.2935 0.833984 11.6666 1.20708 11.6666 1.66732V2.50065H8.33325Z" fill="white" />
                        <path d="M10.4166 7.5L8.33325 11.6667L11.6666 10.8333L9.58325 15" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                        <span className='nav-text'>Charging Monitoring</span></Link>
                    <Link id="t-1" to={'/service-monitoring'} 
                    className={(pathname == "/" || pathname == "/service-monitoring") ? "menu-item active" : "menu-item"} onClick={() => setPathname('service-monitoring')}
                    ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.61827 17.9888C6.22198 17.5731 4.9791 16.8013 3.99497 15.7787C4.3621 15.3436 4.58335 14.7814 4.58335 14.1675C4.58335 12.7868 3.46407 11.6675 2.08335 11.6675C1.99983 11.6675 1.91727 11.6716 1.83585 11.6796C1.72493 11.1373 1.66669 10.5759 1.66669 10.0008C1.66669 9.12973 1.80034 8.28986 2.04827 7.50057C2.05994 7.50073 2.07164 7.50082 2.08335 7.50082C3.46407 7.50082 4.58335 6.38153 4.58335 5.00082C4.58335 4.60444 4.4911 4.22965 4.32694 3.89665C5.29065 3.00057 6.4669 2.32995 7.77173 1.96875C8.18519 2.77919 9.02781 3.33416 10 3.33416C10.9722 3.33416 11.8149 2.77919 12.2283 1.96875C13.5331 2.32995 14.7094 3.00057 15.6731 3.89665C15.5089 4.22965 15.4167 4.60444 15.4167 5.00082C15.4167 6.38153 16.536 7.50082 17.9167 7.50082C17.9284 7.50082 17.9401 7.50073 17.9518 7.50057C18.1997 8.28986 18.3334 9.12973 18.3334 10.0008C18.3334 10.5759 18.2751 11.1373 18.1642 11.6796C18.0828 11.6716 18.0002 11.6675 17.9167 11.6675C16.536 11.6675 15.4167 12.7868 15.4167 14.1675C15.4167 14.7814 15.6379 15.3436 16.0051 15.7787C15.0209 16.8013 13.7781 17.5731 12.3818 17.9888C12.0595 16.9808 11.115 16.2508 10 16.2508C8.88502 16.2508 7.94052 16.9808 7.61827 17.9888Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.99998 12.9173C11.6108 12.9173 12.9166 11.6115 12.9166 10.0007C12.9166 8.38982 11.6108 7.08398 9.99998 7.08398C8.38915 7.08398 7.08331 8.38982 7.08331 10.0007C7.08331 11.6115 8.38915 12.9173 9.99998 12.9173Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                        <span className='nav-text'>Service Monitoring</span></Link>
                    {userInfoData.user_info.isVendor === 0 && !userInfoData.user_info.vendorId && (
                        <>
                            <Link id="t-1" to={'/station'} 
                            className={ pathname == "/station" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/station')}
                            > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.66669 18.334H18.3334" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.25 13.334H11.25V18.334H16.25V13.334Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.8333 4.16602V6.66602" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.6667 4.16602V6.66602" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.6667 4.16602L13.75 1.66602L15.8334 4.16602H11.6667Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.4167 8.33398H4.58333C4.1231 8.33398 3.75 8.70707 3.75 9.16732V18.334" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.25 12.084H7.91667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.25 15H7.91667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.6667 6.66602H10.8334C10.6032 6.66602 10.4167 6.85256 10.4167 7.08268V12.916C10.4167 13.1461 10.6032 13.3327 10.8334 13.3327H16.6667C16.8968 13.3327 17.0834 13.1461 17.0834 12.916V7.08268C17.0834 6.85256 16.8968 6.66602 16.6667 6.66602Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.75 11.25C14.4404 11.25 15 10.6904 15 10C15 9.30964 14.4404 8.75 13.75 8.75C13.0596 8.75 12.5 9.30964 12.5 10C12.5 10.6904 13.0596 11.25 13.75 11.25Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.75 13.334V17.5007" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                <span className='nav-text'>Station Management</span></Link>
                            <Link id="t-1" to={'/customer-database'} 
                            className={ pathname == "/customer-database" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/customer-database')}
                            ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.91667 8.33333C9.5275 8.33333 10.8333 7.0275 10.8333 5.41667C10.8333 3.80584 9.5275 2.5 7.91667 2.5C6.30583 2.5 5 3.80584 5 5.41667C5 7.0275 6.30583 8.33333 7.91667 8.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.5865 2.91602C14.4335 3.42639 15 4.35506 15 5.41602C15 6.47697 14.4335 7.40564 13.5865 7.91602" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.66669 16.9993V17.4993H14.1667V16.9993C14.1667 15.1325 14.1667 14.1991 13.8034 13.4861C13.4838 12.8588 12.9739 12.3489 12.3466 12.0293C11.6336 11.666 10.7002 11.666 8.83335 11.666H7.00002C5.13319 11.666 4.19977 11.666 3.48672 12.0293C2.85951 12.3489 2.34957 12.8588 2.03 13.4861C1.66669 14.1991 1.66669 15.1325 1.66669 16.9993Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.3333 17.4993V16.9993C18.3333 15.1325 18.3333 14.199 17.97 13.486C17.6504 12.8588 17.1405 12.3489 16.5132 12.0293" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                <span className='nav-text'>Customer Database</span></Link>
                            <Link id="t-1" to={'/bookings'} 
                             className={pathname == "/bookings" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/bookings')}
                            ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0834 4.16602H5.41669V18.3327H17.0834V4.16602Z" stroke="white" stroke-linejoin="round" />
                                <path d="M14.5834 4.16602V1.66602H3.33335C3.10324 1.66602 2.91669 1.85257 2.91669 2.08268V15.8327H5.41669" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.75 9.16602H13.75" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.75 12.5H13.75" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                <span className='nav-text'>Manage Booking</span></Link>
                            <Link id="t-1" to={'/vendor-management'} 
                            className={pathname == "/vendor-management" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/vendor-management')}
                            ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_574_570)">
                                    <path d="M10 7.91602C11.3807 7.91602 12.5 6.79673 12.5 5.41602C12.5 4.0353 11.3807 2.91602 10 2.91602C8.61929 2.91602 7.5 4.0353 7.5 5.41602C7.5 6.79673 8.61929 7.91602 10 7.91602Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 9.58203C8.50273 9.62322 7.03585 10.0142 5.71669 10.7237C5.37417 10.9003 5.08707 11.168 4.88708 11.4974C4.68709 11.8268 4.58199 12.205 4.58336 12.5904V12.9737C4.58226 13.0762 4.60149 13.1778 4.63994 13.2728C4.6784 13.3678 4.73531 13.4543 4.80738 13.5271C4.87946 13.6 4.96527 13.6578 5.05984 13.6973C5.15442 13.7367 5.25588 13.757 5.35836 13.757H14.6417C14.7442 13.757 14.8456 13.7367 14.9402 13.6973C15.0348 13.6578 15.1206 13.6 15.1927 13.5271C15.2647 13.4543 15.3217 13.3678 15.3601 13.2728C15.3986 13.1778 15.4178 13.0762 15.4167 12.9737V12.5904C15.4165 12.2064 15.3107 11.8299 15.1108 11.5022C14.9109 11.1744 14.6246 10.9079 14.2834 10.732C12.9649 10.0196 11.498 9.62579 10 9.58203Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.5 0.416016H2.5C2.16848 0.416016 1.85054 0.547712 1.61612 0.782132C1.3817 1.01655 1.25 1.33449 1.25 1.66602V14.9993C1.25 15.3309 1.3817 15.6488 1.61612 15.8832C1.85054 16.1176 2.16848 16.2493 2.5 16.2493H6.66667L10 19.5827L13.3333 16.2493H17.5C17.8315 16.2493 18.1495 16.1176 18.3839 15.8832C18.6183 15.6488 18.75 15.3309 18.75 14.9993V1.66602C18.75 1.33449 18.6183 1.01655 18.3839 0.782132C18.1495 0.547712 17.8315 0.416016 17.5 0.416016Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_574_570">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                                <span className='nav-text'>Vendor Management</span></Link>
                            <Link id="t-1" to={'/users'} 
                            className={ pathname == "/users" ? "menu-item active" : "menu-item"} onClick={() => setPathname('/users')}
                            > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.33331 14.9993V18.3327H16.6666V1.66602H3.33331V4.99935" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.5 12.5H4.16667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.5 10H4.16667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.5 7.5H4.16667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99998 8.74935C10.9205 8.74935 11.6666 8.00316 11.6666 7.08268C11.6666 6.16221 10.9205 5.41602 9.99998 5.41602C9.07951 5.41602 8.33331 6.16221 8.33331 7.08268C8.33331 8.00316 9.07951 8.74935 9.99998 8.74935Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.3334 14.5833C13.3334 12.7424 11.841 11.25 10 11.25C8.15906 11.25 6.66669 12.7424 6.66669 14.5833" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                                <span className='nav-text'>Dashboard User</span></Link>
                        </>
                    )
                    }
                    <Link id="t-1" to={'/feedback'} 
                    className={(pathname == "/" || pathname == "/feedback") ? "menu-item active" : "menu-item"} onClick={() => setPathname('feedback')}
                    ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3334 2.91602H1.66669V15.416H4.58335V17.4993L8.75002 15.416H18.3334V2.91602Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.9167 6.66602V7.08268" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.08331 6.66602V7.08268" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.9166 10.416C12.9166 10.416 12.0833 12.0827 9.99998 12.0827C7.91665 12.0827 7.08331 10.416 7.08331 10.416" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                        <span className='nav-text'>Feedback Management</span></Link>

                        <Link id="t-1" to={'/vehicle-modal'} 
                    className={(pathname == "/" || pathname == "/vehicle-modal") ? "menu-item active" : "menu-item"} onClick={() => setPathname('feedback')}
                    ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3334 2.91602H1.66669V15.416H4.58335V17.4993L8.75002 15.416H18.3334V2.91602Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.9167 6.66602V7.08268" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.08331 6.66602V7.08268" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.9166 10.416C12.9166 10.416 12.0833 12.0827 9.99998 12.0827C7.91665 12.0827 7.08331 10.416 7.08331 10.416" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                        <span className='nav-text'>Vehicle Management</span></Link>
                </div>
            </div>
        </>
    )
}
export default VerticalMenu;
