import {
  Routes,
  Route,
  Navigate,
  RoutesProps,
} from "react-router-dom";

import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { RootState } from "../config/Store";
import { UserState } from "../reducer/AuthReducer";
import { useSelector } from "react-redux";
const Navigation = () => {

  interface ProtectedRouteProps extends RoutesProps {
    isAuthenticated: boolean;
    authenticationPath: string;
  }

  const login: any = useSelector<RootState, UserState>((state: any) => state.userLogin);

  // element = { defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : item.component }

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: localStorage.getItem("token") != null,
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));
  const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
  const Charging = React.lazy(() => import("../pages/Charging/Charging"));
  const Station = React.lazy(() => import("../pages/Station/Station"));
  const Users = React.lazy(() => import("../pages/Users/Users"));
  const Bookings = React.lazy(() => import("../pages/Bookings/Bookings"));
  const VendorManagement = React.lazy(() => import("../pages/VendorManagement/VendorManagement"));
  const Feedback = React.lazy(() => import("../pages/Feedback/Feedback"));
  const Feedbackdetails = React.lazy(() => import("../pages/Feedbackdetails/Feedbackdetails"));
  const ChargingdetailsTab =  React.lazy(() => import("../pages/Charging/Chargin.details.tab"));
  const ServiceMonitoring =  React.lazy(() => import("../pages/Service-Monitoring/Service.Monitoring"));
  const CustomerDatabase = React.lazy(() => import("../pages/Customer-Database/Customer.Database"));
  const ServiceLogs = React.lazy(() => import("../pages/Service-Monitoring/ServiceLogs"));
  const DowntimeLogs = React.lazy(() => import("../pages/Service-Monitoring/DowntimeLogs"));
  const CustomerBookingDetail = React.lazy(() => import("../pages/Customer-Database/CustomerBookingDetail"))
  const StateWiseRevenue = React.lazy(() => import("../pages/Dashboard/StateWiseRevenue"));
  const VehicleModal = React.lazy(() => import("../pages/Vehicle-modal/VehicleModal"));

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>{defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Login />}</Suspense>
            }
          />
          <Route path="/" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Navigate replace to="/login" />} />
          <Route path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >

            <Route path="/dashboard" element={<Suspense fallback={<></>}> <Dashboard /> </Suspense>} />
            <Route path="/state-wise-revenue" element={<Suspense fallback={<></>}> <StateWiseRevenue /> </Suspense>} />
            <Route path="/charging" element={<Suspense fallback={<></>}> <Charging /> </Suspense>} />
            <Route path="/charging-details" element={<Suspense fallback={<></>}> <ChargingdetailsTab /> </Suspense>} />
            <Route path="/station" element={<Suspense fallback={<></>}> <Station /> </Suspense>} />
            <Route path="/users" element={<Suspense fallback={<></>}> <Users /> </Suspense>} />
            <Route path="/bookings" element={<Suspense fallback={<></>}> <Bookings /> </Suspense>} />
            <Route path="/vendor-management" element={<Suspense fallback={<></>}> <VendorManagement /> </Suspense>} />
            <Route path="/customer-database" element={<Suspense fallback={<></>}> <CustomerDatabase /> </Suspense>} />
            <Route path="/feedback" element={<Suspense fallback={<></>}> <Feedback /> </Suspense>} />
            <Route path="/feedbackdetails" element={<Suspense fallback={<></>}> <Feedbackdetails /> </Suspense>} />
            <Route path="/service-monitoring" element={<Suspense fallback={<></>}> <ServiceMonitoring /> </Suspense>} />
            <Route path="/ServiceLogs/:id" element={<Suspense fallback={<></>}> <ServiceLogs /> </Suspense>} />
            <Route path="/DowntimeLogs/:id" element={<Suspense fallback={<></>}> <DowntimeLogs /> </Suspense>} />
            <Route path="/CustomerBookingDetail" element={<Suspense fallback={<></>}> <CustomerBookingDetail /> </Suspense>} />
            <Route path="/vehicle-modal" element={<Suspense fallback={<></>}> <VehicleModal /> </Suspense>} />
          </Route>
        </Routes >
      </div >
    </>
  );
};

export default Navigation;
